<template>
  <div class="city-add main-block col-lg-6">
    <b-spinner v-if="spinnerShow" class="spinner" label="Large Spinner" />
    <form action="" @submit.prevent="save()">
      <div class="city-add__box main-block__head">
        <h1 class="city-add__box-title main-block__title">Информация</h1>
        <button :disabled="disabled" class="main-block__btn default-btn" type="submit">
          Сохранить
        </button>
      </div>
      <ul class="city-add__items">
        <li class="city-add__item item-multiselect flex">
          <p class="city-add__key">Тип транспорта</p>
          <div class="city-add__multiselect">
            <multiselect
              v-if="transportModels"
              v-model="transportModel"
              :options="transportModels.data"
              label="name"
              track-by="id"
            />
            <p v-if="!transportModel" class="error">Выберите транспорт</p>
          </div>
        </li>
        <li class="city-add__item item-multiselect flex">
          <p class="city-add__key">Направления</p>
          <div class="city-add__multiselect">
            <multiselect
              v-if="regionList"
              v-model="regionTitle"
              :options="regionList.data"
              label="title"
              track-by="id"
            />
            <p v-if="!regionTitle" class="error">Выберите направление</p>
          </div>
        </li>
        <li class="city-add__item flex">
          <p class="city-add__key">Расстояние в км по маршруту</p>
          <p>
            <input
              v-model="distance"
              type="text"
              class="cost w-75"
              placeholder="введите км"
              required
              @keypress="isNumber($event)"
            />км
          </p>
        </li>
        <li class="city-add__item flex">
          <p class="city-add__key">Тариф за 1 км (с НДС)</p>
          <p>
            <input
              v-model="cost"
              type="text"
              class="cost w-75"
              placeholder="введите стоимость"
              required
              @keypress="isNumber($event)"
            />тг
          </p>
        </li>
        <li class="city-add__item flex">
          <p class="city-add__key">Стоимость тарифа за 1 рейс</p>
          <p>
            <input
              v-model="cost_route"
              type="text"
              class="cost w-75"
              readonly
            />тг
          </p>
        </li>
      </ul>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      regionTitle: "",
      transportModel: "",
      spinnerShow: false,
      disabled: false,
      cost: "",
      cost_route: "",
      distance: "",
    };
  },
  computed: {
    ...mapState(["regionList", "transportModels", "directionsCost"]),
  },
  async created() {
    this.spinnerShow = true;
    await this.$store.dispatch("getTransportModels");
    await this.$store.dispatch("getRegionList");
    await this.$store.dispatch("getDirectionsCost", { id: this.$route.query.id });

    if (this.directionsCost) {
      this.cost = this.directionsCost.data.cost
      this.cost_route = this.directionsCost.data.cost_route
      this.distance = this.directionsCost.data.distance
      if (this.regionList && this.regionList.data) {        
        if (Array.isArray(this.regionList.data)) {
          await this.regionList.data.forEach((element) => {            
            if (element.title == this.directionsCost.data.directions) {
              this.regionTitle = element;
            }
          });
        }
      }

      if (this.transportModels && this.transportModels.data) {        
        if (Array.isArray(this.transportModels.data)) {
          await this.transportModels.data.forEach((element) => {            
            if (element.name == this.directionsCost.data.transport_type) {
              this.transportModel = element;
            }
          });
        }
      }
    }

    this.spinnerShow = false;
  },
  methods: {
    save() {
      if (this.regionTitle && this.transportModel) {
        this.disabled = true;
        this.$parent.spinnerIn();

        const json = {
          transport_type: this.transportModel.name,
          cost: this.cost,
          cost_route: this.cost_route,
          directions: this.regionTitle.title,
          distance: this.distance,
        };
        if(this.$route.query.id){
          json.id = this.$route.query.id
          this.$api
          .put("/v2/directions-cost", json)
          .then(() => {
            this.disabled = false;
            this.$parent.spinnerOut();
            this.$router.push("/database/cost");
          })
          .catch((e) => {
            this.disabled = false;
            this.$parent.spinnerOut();
            this.$toast.error(e.response?.data?.message);
          });
        }else{
          this.$api
          .post("/v2/directions-cost", json)
          .then(() => {
            this.disabled = false;
            this.$parent.spinnerOut();
            this.$router.push("/database/cost");
          })
          .catch((e) => {
            this.disabled = false;
            this.$parent.spinnerOut();
            this.$toast.error(e.response?.data?.message);
          });
        }
      }
    },
    isNumber(evt) {
      let keysAllowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "."];
      let keyPressed = evt.key;

      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault();
      }

      if(this.cost && this.distance){
        this.cost_route = (this.cost * this.distance).toFixed(2)
      }

    },
  },
  watch: {
    cost(newVal){
      if(newVal && this.distance) {
        this.cost_route = (newVal * this.distance).toFixed(2)
      }
    },
    distance(newVal){
      if(newVal && this.cost){
        this.cost_route = (newVal * this.cost).toFixed(2)
      }
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.item-multiselect:deep(.multiselect__tag) {
  background-color: var(--secondary-color);
}
.item-multiselect:deep(.multiselect__option--highlight) {
  background-color: var(--secondary-color);
}
.item-multiselect:deep(.multiselect__option--highlight::after) {
  background-color: var(--secondary-color);
}
.item-multiselect :deep(.multiselect__option--highlight.multiselect__option--selected) {
  background-color: var(--tertiary-color);
}
.item-multiselect :deep(.multiselect__option--highlight.multiselect__option--selected::after) {
  background-color: var(--tertiary-color);
}
.city-add__items {
  font-size: 12px;
  line-height: 14px;
  color: #707070;
  padding: 3.5px 0 0 0;
}

.city-add__item {
  padding-top: 11.5px;
  min-height: 27.5px;
  position: relative;
}

.city-add__item:not(:last-child) {
  padding-bottom: 11.5px;
  min-height: 39px;
  height: auto;
}

.main__select-icon {
  right: 8px;
  top: 53%;
  width: 7px;
}

.city-add__key {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  color: #000000;
  margin: 0;
  padding: 0;
}

.city-add__value {
  width: 50%;
  padding-bottom: 3px;
  padding-left: 3px;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #acacac;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  padding-right: 10px;
}

.city-add__value_align {
  text-align: end;
}
.city-add__week-item {
  width: 70px;
  height: 30px;
  /*padding: 10px 10px;*/
  box-sizing: border-box;
  background-color: #d8e7ec;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.city-add__week-item:not(:last-child) {
  margin-right: 5px;
}
.city-add__week-active,
.city-add__week-item:hover {
  background-color: #96dfea;
}
.time-input {
  opacity: 0;
}
.city-add__week-input {
  opacity: 1;
}
.city-add__input {
  width: 90%;
  height: 80%;
  padding: 0 0 0 2px;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #acacac;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}

.item-multiselect {
  margin: 0 0 0 0;
}
.city-add__multiselect {
  width: 60%;
}

.cost {
  width: 20%;
  border-bottom: 1px solid #acacac;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}
</style>
